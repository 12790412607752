@import "../../colors";

.button-vertical {
  display: inline-block;
  border-radius: 10px;
  padding: 1rem 2rem;
  text-align: center;
  margin-right: 1rem;
  margin-bottom: 2rem;
  color: $white;
  background: linear-gradient(180deg, #205DD3 0%, #0A2379 100%);
  cursor: pointer;
  width: 140px;
  min-height: 150px;
  &:hover {
    opacity: 0.8;
  }

  &.active {
    color: $brand;
    cursor: default;
    background: linear-gradient(215.17deg, #F9E63B 4.14%, #FDCF3F 97.77%);
    box-shadow: 0px 4px 4px rgba(51, 51, 51, 0.3);

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 480px) {
  .button-vertical {
    width: 130px;
  }
}