@import "../../colors";

.underline-bar {
  position: relative;
}

.underline-bar::before {
  position: absolute;
  bottom: -8px;
  left: calc(50% - 2.5rem);
  transform: translateY(-50%);
  content: "";
  width: 5rem;
  height: 2px;
  background-color: #FFC040;
}

.home-title {
  color: $white;
  line-height: 2.5rem;
}

.how-it-works-container {
  background-image: url("../../../images/new/homeServices/cum_functioneaza.png");
  margin-bottom: 7rem;

  .img-container {
    text-align: right;

    img {
      width: auto;
    }

    div {
      display: none;
    }
  }

  .content-container {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 100px;
  }

  .title-container {
    padding-top: 1rem;
    height: 100px;
  }

  .step-container {
    padding-top: 3rem;

    .step-cta-container {
      text-align: center;

      p {
        margin-bottom: 2rem;
      }

      button {
        margin: auto;
      }
    }

    .step-even {
      margin-right: 2rem;
      float: left;
    }

    .step {
      margin-bottom: 2rem;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      width: calc(50% - 1rem);
      height: 150px;

      div {
        margin-left: 10px;
      }
    }
  }

  .step-container-old {
    padding-top: 3rem;

    .step-cta-container {
      text-align: center;

      button {
        margin: auto;
      }
    }

    .step-even {
      margin-right: 2rem;
      float: left;
    }

    .step {
      margin-bottom: 2rem;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      width: calc(50% - 1rem);
      height: 150px;

      .number {
        font-size: 40px;
        color: #223B99;
        margin-top: -10px;
      }

      div {
        margin-left: 10px;
      }
    }
  }
}

.testimonials-container {
  margin-bottom: 4rem;
  min-height: 12rem;

  .t-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      border-radius: 100%;
      width: 70px;
    }

    div {
      margin-left: 1rem;

      p:first-of-type {
        color: #505D68;
        margin-bottom: 0;
        font-weight: bold;
      }

      p:nth-of-type(2) {
        color: #A1AEB7;
        margin-bottom: 0;
      }
    }
  }

  .t-desc-container {
    padding-top: 1rem;
    //min-height: ;
  }
}

.download-app-container {
  background-image: url("../../../images/new/homeServices/descarca_aplicatia_zumzi.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 10rem;

  .d-app-content {
    padding-top: 10rem;
  }

  .d-phone-mobile {
    display: none;
  }
}

.subscription-home-container {
  padding-top: 7rem !important;
  margin-bottom: 7rem;

  h1 {
    margin-bottom: 2rem;
  }

  & .subscription-home-container-left {
    padding-right: 3rem;

    h1 {
      margin-top: 5rem;
    }

    img {
      width: 100%;
    }

    p {
      margin-bottom: 3rem;
    }
  }

  & .subscription-home-container-right {
    padding-top: 7rem;
    padding-left: 3rem;


    p {
      margin-bottom: 8rem;
    }
  }
}

.service-top-container {
  margin-bottom: 8rem;

  .service-top-container-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .service-top-container-item {
    background: $white;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3rem;
    text-align: center;
    height: 100%;

    h4 {
      margin-top: 1rem;
    }

    p {
      margin-bottom: 3rem;
      margin-top: 1rem;
    }

  }
}

.home-benefits-container {
  h4 {
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
}

.home-become-partner {
  margin-top: 4rem;
  background: #F4F5FA;
  padding-top: 4rem;
  padding-bottom: 5rem;

  h1 {
    color: $black;
  }

  .home-become-partner-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5rem;

    img {
      width: 100%;
    }
  }

  .home-become-partner-right {
    padding: 0 !important;

    .home-become-partner-right-first {
      padding-right: 2rem;
    }
  }

  .home-benefit-item {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;

    div:first-of-type {
      margin-right: 0.5rem;
    }
  }
}

.home-frequent-services-container {
  a {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1399px) {
  .how-it-works-container {

    .content-container {
      padding-left: 4rem;
    }

    .step-container {
      padding-top: 2rem;

      .step {
        width: 100%;
        height: 110px;
      }
    }

    .step-cta-container {
      margin-bottom: 2rem;

      p {
        display: none;
      }
    }
  }
  .service-top-container {
    .service-top-container-item {
      padding: 3rem 1rem;
    }
  }
}

@media (max-width: 1199px) {
  .how-it-works-container {
    .img-container {
      img {
        width: calc(317px + 50vw - 500px);
      }
    }
  }
}

@media (max-width: 992px) {
  .how-it-works-container {
    background-image: none;
    background-color: #F4F5FA;

    .img-container {
      text-align: center;

      img {
        width: 50%;
      }

      div {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }

    .content-container {
      padding-left: calc(var(--bs-gutter-x) * .5);;
      padding-right: calc(var(--bs-gutter-x) * .5);;
      padding-bottom: 2rem;

      .step-container {
        .step {
          padding: 16px 0;

          div {
            margin-left: 0;
          }
        }
      }
    }

    .title-container {
      display: none;
    }


    .step-container {
      .step {
        width: 100%;
        height: auto;
      }

      .step-even {
        float: none;
      }
    }
  }

  .download-app-container {
    background-image: none;
    background-color: #F4F5FA;
    padding-bottom: 0;

    .d-app-content {
      padding-top: 4rem;
      padding-bottom: 2rem;

      .d-download-links {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }

    .d-phone-mobile {
      display: block;
      text-align: right;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;

      img {
        width: 100%;
      }
    }
  }

  .subscription-home-container {
    & .subscription-home-container-left {
      p {
        margin-bottom: 2rem;
      }

      img {
        width: 75%;
        margin: auto;
        display: block;
      }
    }

    & .subscription-home-container-right {
      padding-top: 2rem;
      padding-left: calc(var(--bs-gutter-x) * .5);

      h1 {
        margin-top: 2rem;
      }

      p {
        margin-bottom: 2rem;
      }

      img {
        max-width: 50%;
      }
    }
  }
  .home-benefits-container {
    h4 {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    img {
      max-width: 75%;
    }
  }
  .testimonials-container {
    min-height: auto;
    margin-bottom: 2rem;
  }
  .home-frequent-services-container {
    h1 {
      text-align: left !important;
    }
  }
  .home-become-partner {
    .home-become-partner-left {
      margin-bottom: 2rem;

      img {
        width: 50%;
      }
    }
  }
}

@media (max-width: 576px) {
  .home-title {
    line-height: 1.75rem;
    font-size: 1.25rem;
  }

  .how-it-works-container {
    background-image: none;
    background-color: #F4F5FA;

    .img-container {
      text-align: center;

      img {
        width: 75%;
      }
    }
  }
}

